import React from "react"
import { Row } from "react-bootstrap"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../components"

export default function Contact() {
  return (
    <Layout>
      <Helmet
        bodyAttributes={{
          class: "contact",
        }}
      />
      <Row className="content-container contact-image container">
        <div className="row">
          <div className="contact-text col-lg-6 col-md-12">
            <div>
              <h1>Zapraszam do kontaktu</h1>
            </div>
            <hr className="line"></hr>
            <div className="full-name">
              <h4>Kancelaria Prawna</h4>
              <h4>Radosław Rybak</h4>
            </div>
            <div className="contact-address">
              <p>
                <i className="fas fa-envelope"></i> ul. Modrzewiowa 4
                Jeszkowice, 55-003 Czernica
              </p>
              <p>
                <i className="fas fa-phone"></i> (+48) 606 478 985
              </p>
              <p>
                <i className="fas fa-at"></i> biuro@rybak-kancelaria.pl
              </p>
              <p>
                <i className="fas fa-info-circle"></i> NIP: 7511668205
              </p>
            </div>
            <div className="contact-payment">
              <p>Nr rachunku bankowego: PKO BP S.A.</p>
              <p>59 1020 5242 0000 2702 0399 0512</p>
            </div>
          </div>
          <div className="form col-lg-6 col-md-12">
            <ContactForm />
          </div>
        </div>
      </Row>
    </Layout>
  )
}
